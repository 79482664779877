<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
    hide-overlay transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <span>Compose</span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            v-on="{ ...d, ...tt }"
            color="color3 color3Text--text"
            class="ma-0"
            fab
            :bottom ="fixed"
            :right="fixed"
            :fixed="fixed"
            :style="fixed ? `margin-right: 60px !important` : ''"
          >
            <v-icon>fas fa-edit</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-btn color="color1Text" text icon>
          <v-icon @click.stop="cancel">fas fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Compose</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1Text"
          text
          :disabled="!valid"
          :loading="loading"
        >Send</v-btn>
      </v-toolbar>
      <v-card-text class="fill-height">
        <v-container>
          <v-row dense>
            <!-- Recipients -->
            <v-col cols="12">
              <v-card color="grey lighten-3">
                <v-card-title class="subheading" style="min-height: 68px">
                  <b>Recipients</b>
                  <v-chip v-if="(selectedPlayers.length + manualEmails.length) > 0" color="color3Text color3--text" class="ml-2">
                    <v-avatar
                      color="color3"
                      class="color3Text--text mr-2"
                      size="36"
                      v-text="selectedPlayers.length + manualEmails.length"
                    ></v-avatar>
                    {{selectedPlayers.length}} Players {{manualEmails.length > 0 ? `+ ${manualEmails.length} additional addresses` : null}}
                  </v-chip>
                </v-card-title>
                <v-divider></v-divider>
                <v-tabs
                  v-model="tab"
                  color="color3"
                  slider-color="color3"
                  background-color="grey lighten-3"
                >
                  <v-tab key="division">By Division</v-tab>
                  <v-tab key="team">By Team</v-tab>
                </v-tabs>
                <v-card-text>
                  <v-tabs-items v-model="tab" class="grey lighten-3">
                    <v-tab-item key="division">
                        <div class="body-2">Please select the divisions to send this too</div>
                        <v-select
                          :items="tournament.publicDivisions"
                          item-text="name"
                          item-value="id"
                          v-model="selectedDivisionIds"
                          label="Divisions"
                          hint="Choose divisions to include"
                          persistent-hint
                          multiple
                          chips
                          deletable-chips
                          color="color3"
                          item-color="color3"
                          ref="select1"
                          solo
                        >
                          <v-list-item
                            slot="prepend-item"
                            ripple
                            @click="toggleDivision"
                            :color="selectIcon.color"
                          >
                            <v-list-item-action>
                              <v-icon :color="selectIcon.color">{{selectIcon.name}}</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item>
                          <v-divider
                            slot="prepend-item"
                            class="mt-2"
                          ></v-divider>
                        </v-select>
                        <v-row dense>
                          <v-switch color="success" :readonly="onlyWaitlist" class="shrink mr-3" label="Include Waitlist Teams" v-model="includeWaitlist"></v-switch>
                          <v-switch color="success" class="shrink" label="Only Waitlist Teams" v-model="onlyWaitlist"></v-switch>
                        </v-row>
                        <v-row dense>
                          <v-switch color="success" :readonly="onlyWithdrawn" class="shrink mr-3" label="Include Withdrawn Teams" v-model="includeWithdrawn"></v-switch>
                          <v-switch color="success" class="shrink" label="Only Withdrawn Teams" v-model="onlyWithdrawn"></v-switch>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="team">
                      <div class="title">{{selected.length}} Teams Currently Selected</div>
                      <v-btn
                        color="color3"
                        text
                        @click.stop="showTableManual = !showTableManual"
                        v-if="teamIds"
                      >
                        {{showTable ? 'hide' : 'show more'}} Teams
                      </v-btn>
                      <v-expand-transition>
                        <div v-if="showTable">
                          <v-text-field
                            label="Search"
                            v-model="search"
                            append-icon="fas fa-search"
                            solo-inverted
                            clearable
                          ></v-text-field>
                          <v-data-table
                            :headers="teamHeaders"
                            :items="teamsWithDivision"
                            class="elevation-1"
                            show-select
                            item-key="id"
                            :search="search"
                            v-model="selected"
                            dense
                          >
                            <template v-slot:[`item.wait`]="{ item }">
                              <v-icon v-if="item.wait" color="success">fas fa-check</v-icon>
                            </template>
                            <template v-slot:[`item.withdrawn`]="{ item }">
                              <v-icon v-if="item.withdrawn" color="success">fas fa-check</v-icon>
                            </template>
                          </v-data-table>
                        </div>
                      </v-expand-transition>
                    </v-tab-item>
                  </v-tabs-items>
                  <!-- Advanced -->
                  <v-row dense justify="end">
                    <v-btn
                      color="color3"
                      text x-small
                      @click.stop="advanced = !advanced"
                      :disabled="manualEmails.length > 0 || window === 1"
                    >{{advanced ? 'hide' : 'show'}} advanced</v-btn>
                  </v-row>
                  <v-expand-transition>
                    <v-row dense v-if="(advanced || manualEmails.length) && window === 0">
                      <v-expand-transition>
                        <v-col cols="12" v-if="manualEmails.length">
                          <div>Additional Emails To Include</div>
                          <v-chip-group
                            active-class="primary--text"
                            column
                          >
                            <v-chip
                              v-for="email in manualEmails"
                              :key="email"
                              close
                              @click:close="removeEmail(email)"
                            >
                              {{ email }}
                            </v-chip>
                          </v-chip-group>
                        </v-col>
                      </v-expand-transition>
                      <v-col cols="12">
                        <v-btn color="color3 color3Text--text" @click="dialog2 = true">
                          Add additional email addresses
                        </v-btn>
                        <v-dialog
                          v-model="dialog2"
                          scrollable
                          max-width="500px"
                          transition="dialog-transition"
                        >
                          <v-card>
                            <v-toolbar color="color2 color2Text--text">
                              <v-toolbar-title>Add Email Addresses</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn color="color1 color1Text--text" small fab @click="dialog2 = false">
                                <v-icon>fas fa-times</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <div>Enter the email addresses to add. Please, seperated each address with a comma.</div>
                              <v-text-field
                                label="Email Addresses"
                                v-model="addAddresses"
                              ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                              <v-fab-transition>
                                <v-btn
                                  :key="`${!addAddresses}-asd`"
                                  color="success white--text"
                                  fab small
                                  @click.stop="addEmails"
                                  :disabled="!addAddresses"
                                >
                                  <v-icon>fas fa-save</v-icon>
                                </v-btn>
                              </v-fab-transition>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Message -->
            <v-col cols="12">
              <v-card color="grey lighten-3">
                <v-card-title class="subheading">
                  <b>Message</b>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="allowSms"
                    color="color3"
                    x-small text
                    @click.stop="toggleSms"
                  >
                    Use {{window === 1 ? 'email' : 'sms'}}
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <!-- Message -->
                <v-window v-model="window" touchless>
                  <v-window-item key="email">
                    <v-card-text>
                      <!-- Subject/Body -->
                      <v-row dense>
                        <v-col cols="12" class="pa-0">
                          <div class="body-2">Subject:</div>
                          <v-text-field
                            label="Subject*"
                            v-model="sparkpost.subject"
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          <div class="body-2">Body:</div>
                          <html-editor v-model="sparkpost.paragraph[0].text" :no-header="true" :no-card="true"></html-editor>
                        </v-col>
                      </v-row>
                      <!-- Closing -->
                      <v-row dense class="pt-4">
                        <v-col cols="12" sm="6" md="3">
                          <div class="body-2">Closing:</div>
                          <v-text-field
                            label="Closing*"
                            v-model="sparkpost.closing"
                            solo
                            persistent-hint
                            hint="i.e. Thank you, See you at the beach, Sincerely"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Signature -->
                      <v-row dense>
                        <v-col cols="12" sm="6" md="3">
                          <div class="body-2">Signature:</div>
                          <v-text-field
                            label="Signature*"
                            v-model="sparkpost.signature"
                            solo
                            persistent-hint
                            hint="Your name or you organizations name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-window-item>
                  <!-- SMS -->
                  <v-window-item key="sms">
                    <v-card-text>
                      <!-- Body -->
                      <v-row dense>
                        <v-col cols="12" class="pa-0">
                          <v-textarea
                            label="Body"
                            counter="160"
                            rows="3"
                            background-color="white"
                            color="black"
                             v-model="smsBody"
                          ></v-textarea>
                          <div></div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-tooltip left>
          <span>Send</span>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="color3 color3Text--text"
              fab
              bottom
              right
              fixed
              :style="`z-index: 5`"
              @click.stop="send"
              :disabled="!valid"
              :loading="loading"
            >
              <v-icon>fas fa-paper-plane</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapGetters } from 'vuex'
import flatten from '../../helpers/ArrayFlatten'
import Sparkpost from '@/classes/Sparkpost'
import HtmlEditor from '@/components/Utils/HtmlEditor.vue'
import uniq from 'lodash.uniq'
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['active', 'fixed', 'compose', 'teamIds'],
  data () {
    return {
      dialog: false,
      selectedDivisionIds: [],
      includeWithdrawn: false,
      includeWaitlist: false,
      onlyWithdrawn: false,
      onlyWaitlist: false,
      sparkpost: new Sparkpost(),
      loading: false,
      sent: false,
      advanced: false,
      manualEmails: [],
      dialog2: false,
      addAddresses: null,
      tab: null,
      teamHeaders: [
        { text: 'Team', value: 'name' },
        { text: 'Division', value: 'division' },
        { text: 'Withdrawn', value: 'withdrawn' },
        { text: 'On Waitlist', value: 'wait' }
      ],
      pager: {},
      search: null,
      selected: [],
      showTableManual: false,
      window: 0,
      smsBody: null
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user']),
    showTable () {
      return !this.teamIds || this.showTableManual
    },
    allowSms () {
      return this.tournament.smsEnabled
    },
    valid () {
      return (this.sparkpostValid || this.smsValid) && this.selected.length + this.selectedDivisionIds.length + this.manualEmails.length > 0
    },
    sparkpostValid () {
      return this.window === 0 && this.sparkpost.valid
    },
    smsValid () {
      return this.window === 1 && this.smsBody && this.smsBody.length > 3
    },
    selectIcon () {
      if (this.allDivisions) return { name: 'fas fa-check-square', color: 'color3' }
      if (this.someDivisions) return { name: 'fas fa-minus-square', color: 'color3' }
      return { name: 'far fa-square', color: '' }
    },
    allDivisions () {
      return this.selectedDivisionIds.length === this.tournament.publicDivisions.length
    },
    someDivisions () {
      return this.selectedDivisionIds.length > 0 && !this.allDivisions
    },
    selectedDivisions () {
      return this.tournament.publicDivisions.filter(d =>
        this.selectedDivisionIds.includes(d.id)
      )
    },
    teamsWithDivision () {
      return flatten(this.tournament.divisions.map(division => {
        return division.teams.map(t => {
          return {
            id: t.id,
            name: t.name,
            team: t,
            division: division.name,
            wait: t.waitlist,
            withdrawn: t.isDeleted
          }
        })
      }))
    },
    selectedTeams () {
      if (this.selected.length) {
        return this.selected.map(m => m.team)
      }
      return flatten(this.selectedDivisions.map(division => {
        return this.includeWithdrawn ? division.teams : division.activeTeams
      }))
    },
    selectedPlayers () {
      return flatten(this.selectedTeams.map(team => {
        return team.players
      }))
    },
    dto () {
      return this.window === 1 ? {
        tournamentId: this.tournament.id,
        includeWithdrawn: this.includeWithdrawn,
        includeWaitlist: this.includeWaitlist,
        onlyWithdrawn: this.onlyWithdrawn,
        onlyWaitlist: this.onlyWaitlist,
        divisionIds: this.selectedDivisionIds,
        smsBody: this.smsBody,
        when: null,
        recipients: this.manualEmails,
        teamIds: this.selected.length ? this.selected.map(m => m.id) : []
      } : {
        tournamentId: this.tournament.id,
        includeWithdrawn: this.includeWithdrawn,
        includeWaitlist: this.includeWaitlist,
        onlyWithdrawn: this.onlyWithdrawn,
        onlyWaitlist: this.onlyWaitlist,
        divisionIds: this.selectedDivisionIds,
        sparkpost: this.sparkpost,
        when: null,
        recipients: this.manualEmails,
        teamIds: this.selected.length ? this.selected.map(m => m.id) : []
      }
    }
  },
  methods: {
    toggleSms () {
      if (this.window === 0) {
        this.window = 1
      } else {
        this.window = 0
      }
    },
    addEmails () {
      this.manualEmails.push(...this.addAddresses.split(','))
      this.addAddresses = null
      this.manualEmails = uniq(this.manualEmails)
      this.dialog2 = false
    },
    removeEmail (e) {
      this.manualEmails = this.manualEmails.filter(f => f !== e)
    },
    toggleDivision () {
      this.$nextTick(() => {
        if (this.allDivisions) {
          this.selectedDivisionIds = []
        } else {
          this.selectedDivisionIds = this.tournament.publicDivisions.map(d => d.id)
          if (this.$refs.select1) this.$refs.select1.blur()
        }
      })
    },
    init () {
      this.sparkpost = new Sparkpost()
      this.selectedDivisionIds = []
      this.includeWithdrawn = false
      this.includeWaitlist = false
      this.onlyWithdrawn = false
      this.onlyWaitlist = false
      this.sent = false
      this.advanced = false
      this.manualEmails = []
      this.dialog2 = false
      this.addAddresses = null
      this.tab = null
      this.search = null
      this.selected = []
      this.sparkpost.subject = `UPDATE: ${this.tournament.name}`
      this.sparkpost.closing = 'See ya at the beach,'
      this.sparkpost.signature = this.user.fullname
      this.sparkpost.preheader = `Tournament Update from ${this.tournament.organization.name}`
      if (this.teamIds) {
        this.$nextTick(() => {
          this.selected.push(...this.teamIds.map(m => this.teamsWithDivision.find(f => f.id === m)))
        })
        this.tab = 1
      }
      this.window = 0
      this.smsBody = null
    },
    send () {
      this.loading = true
      this.$VBL.post.notification(this.dto)
        .then(r => {
          this.sent = true
          this.$emit('sent')
          this.dialog = false
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    cancel () {
      this.dialog = false
    },
    externalCompose () {
      this.dialog = true
    }
  },
  watch: {
    compose: function (val) {
      if (val) {
        this.externalCompose()
      } else {
        this.dialog = false
      }
    },
    dialog: function (val) {
      if (val) {
        this.init()
        return
      }
      this.$emit('clear')
      this.$store.commit(mutations.SET_TEAM_TO_MSG, false)
    },
    tab (val) {
      this.selected = []
      this.selectedDivisionIds = []
    },
    onlyWithdrawn (val) {
      if (val) {
        this.includeWithdrawn = true
        this.includeWaitlist = false
        this.onlyWaitlist = false
      }
    },
    onlyWaitlist (val) {
      if (val) {
        this.includeWaitlist = true
        this.includeWithdrawn = false
        this.onlyWithdrawn = false
      }
    }
  },
  mounted () {
    if (this.compose) this.externalCompose()
  },
  components: {
    HtmlEditor
  }
}
</script>

<style>

</style>
