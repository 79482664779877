<template>
  <v-card>
    <loading-bar :value="loading"/>
    <v-container>
      <v-window v-model="window" touchless>
        <!-- SENT BOX -->
        <v-window-item :value="0">
          <v-row dense justify="space-between">
              <span class="title">Notification Center</span>
              <v-btn color="color3" text icon @click.stop="load" :loading="loading">
                <v-icon>fas fa-sync-alt</v-icon>
              </v-btn>
          </v-row>
          <v-row dense>
            <v-text-field
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="search"
              solo-inverted
              clearable
            ></v-text-field>
          </v-row>
          <v-list two-line v-if="emails.length">
            <v-data-iterator
              :items="emails"
              :items-per-page="25"
            >
              <template v-slot:default="{ items }">
                <template v-for="(email, n) in items">
                  <v-divider :key="`divider_${n}`"></v-divider>
                  <v-list-item :key="`tile_${n}`" @click.stop="selectedEmail = email">
                    <v-list-item-avatar>
                      <v-icon>fas fa-{{email.sms ? 'sms' : 'envelope'}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{email.subject}}</v-list-item-title>
                      <v-list-item-subtitle v-if="email.tournamentEmail && email.tournamentEmail.divisionIds && email.tournamentEmail.divisionIds.length">
                        <v-chip small v-for="id in email.tournamentEmail.divisionIds" :key="id">{{getDivisionName(id)}}</v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        {{email.recipientSummary}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="caption">
                        {{email.status}}
                        <v-progress-circular
                          v-if="email.status === 'Sending'"
                          :size="20"
                          color="success"
                          indeterminate></v-progress-circular>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>{{ email.startDate.calendar() }}</v-list-item-action-text>
                      <v-icon></v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </template>
            </v-data-iterator>
          </v-list>
          <v-row dense v-else>
            <v-col cols="12" class="title text-center pt-6">
              You do not have any sent messages.
            </v-col>
            <v-col cols="12" class="title text-center pt-6">
              <v-btn color="color3 color3Text--text" class="pa-6" @click.stop="compose = true">
                Compose a message
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-right" cols="12" >
              <tournament-email-compose
                @sent="load"
                @clear="compose = false"
                :fixed="true"
                :teamIds="messageTeamIds"
                :compose="compose"
              ></tournament-email-compose>
            </v-col>
          </v-row>
        </v-window-item>
        <!-- REVIEW -->
        <v-window-item :value="1">
          <v-row dense justify="space-between">
              <v-btn color="color3" text icon @click.stop="selectedEmail = null" class="ma-0">
                <v-icon>fas fa-angle-left</v-icon>
              </v-btn>
          </v-row>
          <template v-if="selectedEmail">
            <v-divider></v-divider>
            <v-row dense>
              <v-col cols="12" class="title">
                <v-icon>fas fa-{{selectedEmail.sms ? 'sms' : 'envelope'}}</v-icon> {{selectedEmail.sms ? 'Text Message' : 'Email '}}
              </v-col>
              <v-col cols="12" class="caption grey--text">
                {{selectedEmail.startDate.format('LLLL')}}
              </v-col>
              <v-col cols="12" class="caption grey--text" v-if="!selectedEmail.sms">
                From: {{selectedEmail.from}}
              </v-col>
              <v-col cols="6" class="caption grey--text" v-if="showTeams">
                To: {{selectedEmail.sentTeamIds.length}} Teams
              </v-col>
              <v-col cols="6" class="caption grey--text" v-else>
                To: {{selectedEmail.recipientSummary}}
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  color="color3"
                  text
                  class="xsmall ma-0 pa-0"
                  @click="showDetails = !showDetails"
                >{{showDetails ? 'hide' : 'details'}}</v-btn>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row dense v-if="showDetails">
                <v-col cols="12" class="caption grey--text">
                  Created By: {{selectedEmail.createdBy || 'System'}}
                </v-col>
                <template v-if="showTeams">
                  <v-col cols="12" v-for="obj in sentDetails" :key="obj.id">
                    <b>{{obj.team.name}}</b>: <v-chip small v-for="(email, i) in obj.emails" :key="i">{{`${email.Name} &lt;${email.Email}>`}}</v-chip>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12" v-for="obj in selectedEmail.emailList" :key="obj.email">
                    <v-chip small>{{obj.Name ? `${obj.Name} &lt;${obj.Email}>` : `${obj.Email}`}}</v-chip>
                  </v-col>
                </template>
                <template v-if="selectedEmail.tournamentEmail && selectedEmail.tournamentEmail.recipients.length">
                  <v-col cols="12">
                    <b>Additional Emails:</b>
                  </v-col>
                  <v-col cols="12" v-for="obj in selectedEmail.tournamentEmail.recipients" :key="obj">
                    <v-chip small>{{obj}}</v-chip>
                  </v-col>
                </template>
              </v-row>
            </v-expand-transition>
            <v-divider></v-divider>
            <v-row dense class="pt-4" v-if="!selectedEmail.sms">
              <v-col cols="12">
                <span class="caption grey--text">Subject: </span><span class="subheading">{{selectedEmail.subject}}</span>
              </v-col>
              <v-col cols="12" class="caption grey--text">
                Message:
              </v-col>
              <template v-if="selectedEmail.body">
                <v-col cols="12" v-for="(p, i) in selectedEmail.body" :key="i" v-html="p.text"></v-col>
                <v-col cols="12" v-if="selectedEmail.signature !== 'none'">
                  <div>{{selectedEmail.closing}}</div>
                  <div>{{selectedEmail.signature}}</div>
                </v-col>
              </template>
              <v-col cols="12" v-else>
                <v-alert type="info" :value="true" prominent text>
                  This email uses an email template. The body of that template is not currently visible in this event mailbox. Please contact <a href="mailto:support@volleyballlife.com">support@volleyballlife.com</a> if you need to see a copy.
                </v-alert>
              </v-col>
            </v-row>
            <v-row dense class="pt-4" v-else>
              <v-col cols="12" class="caption grey--text">
                Message:
              </v-col>
              <v-col cols="12">{{selectedEmail.body}}</v-col>
            </v-row>
          </template>
        </v-window-item>
      </v-window>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import TournamentEmail from '@/classes/TournamentEmail'
import TournamentEmailCompose from '@/components/Notifications/TournamentEmailCompose'
import firstBy from 'thenby'

export default {
  props: ['active'],
  data () {
    return {
      loading: false,
      sentEmails: [],
      selectedEmail: null,
      window: 0,
      showDetails: false,
      timeout: null,
      search: null,
      compose: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user', 'getTeam', 'messageTeamIds']),
    a () {
      return this.emails.map(m => {
        return {
          recipientSummary: m.recipientSummary,
          emailList: m.emailList,
          sentTeamIds: m.sentTeamIds
        }
      })
    },
    emails () {
      var list = this.sentEmails.map(m => new TournamentEmail(m))
      list.sort(firstBy('timestamp', -1))
      const s = this.search
      if (s) {
        list = list.filter(f => f.emailList.filter(e => e.Email.includes(s)).length)
      }
      return list
    },
    sentDetails () {
      return this.selectedEmail && this.selectedEmail.sentDetails.map(m => {
        m.team = this.getTeam(m.id)
        return m
      })
    },
    showTeams () {
      return this.selectedEmail.sentTeamIds && this.selectedEmail.sentTeamIds.length
    },
    statuses () {
      return this.sentEmails.map(m => m.status)
    },
    sending () {
      return this.statuses.includes('Sending')
    }
  },
  methods: {
    load () {
      if (this.messageTeamIds && this.messageTeamIds.length) {
        this.compose = true
      }
      this.loading = true
      this.$VBL.get.notification(this.tournament.id)
        .then(r => { this.setMail(r.data) })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    getDivisionName (id) {
      const d = this.tournament.divisions.find(f => f.id === id)
      return d ? d.name : null
    },
    setMail (mail) {
      this.sentEmails = mail
      this.$nextTick(() => {
        if (this.sending) {
          this.timeout = setTimeout(this.load, 5000)
        }
      })
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.load()
      } else {
        this.compose = false
      }
    },
    selectedEmail: function (val) {
      this.window = val ? 1 : 0
      console.log(this.selectedEmail.body)
    }
  },
  mounted () {
    this.load()
  },
  components: {
    TournamentEmailCompose
  },
  destroyed () {
    if (this.timeout) clearTimeout(this.timeout)
  }
}
</script>

<style>

</style>
